import React, { Fragment } from 'react';
import Link from 'next/link';
import Lottie from 'lottie-react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useMediaQuery, useTheme } from '@mui/material';
import animationData from '../public/assets/lottie/home-lotti.json';
import Image from 'next/image'; 
import airedify from "../public/assets/3d-headshot.png";

const IntroComponent = ({ en, theme, getThemedContent }) => {
  // Use MUI's useTheme and useMediaQuery hooks to handle responsiveness
  const themeMUI = useTheme();
  const isMobile = useMediaQuery(themeMUI.breakpoints.down('sm'));

  return (
    <div className="row">
      <div className="col-md-6" data-aos="zoom-in">
        <div className="about-me-box shadow-box">
          <Link className="overlay-link" href="/bio" />
          <img className="bg-img" src="/assets/bg1.png" alt="background" />
          <div className="img-box">
          {!isMobile ? (
              <div style={{ width: '100%', height: '100%' }}>
                <Lottie animationData={animationData} loop={true} style={{ width: '100%', height: '100%' }} />
              </div>
            ) : (
              <Image src={airedify} alt="airedify" layout="fill" objectFit="cover" />
            )}
          </div>
          <div className="infos">
            <b>{en.landing.bio.caption}</b>
            <h1>{en.landing.bio.heading}</h1>
            <p>{en.landing.bio.description}</p>
            <br />
            <p
              dangerouslySetInnerHTML={{
                __html: en.landing.bio.descriptionExtended,
              }}
            ></p>
            <br />
            <p>{en.landing.bio.location}</p>
            <Link href={en.landing.bio.button.link} className="about-btn">
              <img
                src={getThemedContent(theme, en.landing.bio.button.icon)}
                alt="Button"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="about-credentials-wrap">
          {/* Ads banner */}
          <div data-aos="zoom-in">
            <div className="banner shadow-box">
              <div className="marquee">
                <div>
                  <a
                    href="https://chat.whatsapp.com/BtBjxESWWUG5BEFwaofIgz"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      JOIN OUR WHATSAPP{' '}
                      <b style={{ color: 'green' }}>COMMUNITY</b>{' '}
                      <WhatsAppIcon
                        style={{ color: '#25D366', marginLeft: '8px' }}
                      />
                      {Array(4)
                        .fill(0)
                        .map((_, index) => (
                          <Fragment key={index}>
                            JOIN OUR WHATSAPP{' '}
                            <b
                              style={{
                                color: 'green',
                                fontWeight: 'bold',
                              }}
                            >
                              {' '}
                              COMMUNITY{' '}
                            </b>{' '}
                            <WhatsAppIcon
                              style={{
                                color: '#25D366',
                                marginLeft: '8px',
                                padding: '1.5px',
                              }}
                            />
                          </Fragment>
                        ))}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Ads banner ends*/}
          <div className="gx-row d-flex gap-24">
            <div data-aos="zoom-in">
              <div className="about-crenditials-box info-box shadow-box h-full">
                <Link className="overlay-link" href="/credentials" />
                <img src="/assets/bg1.png" alt="background" className="bg-img" />
                <img
                  src={en.landing.credentials.media}
                  alt="credentials"
                />
                <div className="d-flex align-items-center justify-content-between">
                  <div className="infos">
                    <b>{en.landing.credentials.caption}</b>
                    <h1>{en.landing.credentials.heading}</h1>
                  </div>
                  <Link
                    href={en.landing.credentials.button.link}
                    className="about-btn"
                  >
                    <img
                      src={getThemedContent(
                        theme,
                        en.landing.credentials.button.icon
                      )}
                      alt="button"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in">
              <div className="about-project-box info-box shadow-box h-full">
                <Link className="overlay-link" href="/portfolio" />
                <img src="/assets/bg1.png" alt="BG" className="bg-img" />
                <img src={en.landing.cv.media} alt="My Works" />
                <div className="d-flex align-items-center justify-content-between">
                  <div className="infos">
                    <b>{en.landing.cv.caption}</b>
                    <h1>{en.landing.cv.heading}</h1>
                  </div>
                  <Link
                    href={en.landing.cv.button.link}
                    className="about-btn"
                  >
                    <img
                      src={getThemedContent(
                        theme,
                        en.landing.cv.button.icon
                      )}
                      alt="button"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroComponent;
